// import React from 'react';
import React, { useState } from 'react';

import "./i18n"; //import this
// import i18n from './i18n'; // Assurez-vous d'importer votre instance i18n ici

import { useTranslation } from "react-i18next";

import { BsFillTelephoneFill, BsEnvelopeAt } from "react-icons/bs";


import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';


import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';


import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';


import icon from '../img/icon-1080x1080.png'


const Contact = () => {
  const { t, i18n} = useTranslation();

  const detectedLanguage = i18n.language || 'en';
  const [selectedLanguage, setSelectedLanguage] = useState(detectedLanguage);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
  };

    const numeroTelephone = '+41 79 280 89 09'; // Remplacez par votre numéro de téléphone
    const adresseEmail = 'guillaume@locher-ge.ch'; // Remplacez par votre adresse e-mail


  return (
    // <div style={{ fontSize: "0.4em", color: "gray", textAlign: 'right', fontWeight:"150" }}>
    <div style={{ color: "gray", textAlign:'center' }}>
        <br></br>
        <br></br>

        <div>
          {/* <Button variant="outline-secondary" onClick={() => i18n.changeLanguage('en')}>English</Button>
          &nbsp;
          <Button variant="outline-secondary" onClick={() => i18n.changeLanguage('fr')}>Français</Button>
          &nbsp;
          <Button variant="outline-secondary" onClick={() => i18n.changeLanguage('de')}>Deutsch</Button> */}


          {/* <ToggleButtonGroup type="radio" name="langue" defaultValue={detectedLanguage}
            // onChange={() => i18n.changeLanguage(event.target.value)}
            onChange={(value) => i18n.changeLanguage(value)}
            size='sm'
          >
            
            <ToggleButton variant="outline-secondary" id="tbg-lang-1" value={'fr'}>
              Français &nbsp; <img src={icon} style={{maxHeight: "3vh", maxWidth: "3vh", margin: "0 auto", textAlign:"right"}}/>
            </ToggleButton>

            <ToggleButton variant="outline-secondary" id="tbg-lang-2" value={'en'}>
              English
            </ToggleButton>
            
            <ToggleButton variant="outline-secondary" id="tbg-lang-3" value={'de'}>
              Deutsch
            </ToggleButton>

          </ToggleButtonGroup> */}

          

          <DropdownButton
            title={t("langue")}
            variant="outline-secondary"
            id="dropdown-menu-align-end"
            drop={'up-centered'}

            defaultValue={detectedLanguage}
            onSelect={(eventKey) => changeLanguage(eventKey)}
              // i18n.changeLanguage(eventKey)}}

            size='sm'
          >
            <Dropdown.Item eventKey="fr" active={selectedLanguage === 'fr'} >Français</Dropdown.Item>
            <Dropdown.Item eventKey="de" active={selectedLanguage === 'de'} >Deutsch</Dropdown.Item>
            <Dropdown.Item eventKey="it" active={selectedLanguage === 'it'} >Italiano</Dropdown.Item>
            <Dropdown.Item eventKey="en" active={selectedLanguage === 'en'} >English</Dropdown.Item>
            {/* <Dropdown.Item eventKey="jp" active={selectedLanguage === 'jp'} >日本語</Dropdown.Item> */}
          </DropdownButton>



        </div>
        {/* <p><BsFillTelephoneFill /> Téléphone : +41 79 280 89 09 </p>
        <p><BsEnvelopeAt /> e-mail : guillaume@locher-ge.ch </p> */}
        
        <br/>

        
        <a href={`tel:${numeroTelephone}`} style={{ color: "gray", textDecoration: 'none', }}><BsFillTelephoneFill /> {t("contact.phone")} : {numeroTelephone}</a>
        <br></br>
        <a href={`mailto:${adresseEmail}`} style={{ color: "gray", textDecoration: 'none', }}><BsEnvelopeAt /> {t("contact.mail")} : {adresseEmail}</a>

        

    </div>
  );
};

export default Contact;

// import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import Backend from "i18next-xhr-backend";
// import { initReactI18next } from "react-i18next";

// const fallbackLng = ["en"];

// i18n
//   .use(Backend) // used to load data from othe directory
//   .use(LanguageDetector) // detects the current language
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     fallbackLng, // default language
//     detection: {
//       checkWhitelist: true,
//     },
//     debug: false,
//     interpolation: {
//       escapeValue: false, // no need for react. it escapes by default
//     },
//   });

// export default i18n;





// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";

// // i18n
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     resources: {
//       en: {
//         translation: {
//           fname: "First Name",
//           lname: "Last Name",
//           mobile: "Mobile Number",
//           address: "Address",
//           message: "Message",
//         },
//       },
//       fr: {
//         translation: {
//           fname: "Prénom",
//           lname: "Nom",
//           mobile: "Mobiel numéro",
//           address: "Adressse",
//           message: "Message",
//         },
//       },
//       nl: {
//         translation: {
//           fname: "Voornaam",
//           lname: "Achternaam",
//           mobile: "Mobiel nummer",
//           address: "Adres",
//           message: "Bericht",
//         },
//       },
//       ["nl-BE"]: {
//         translation: {
//           fname: "Voornaam",
//           lname: "Achternaam",
//           mobile: "mobiel nummer",
//           address: "Adres",
//           message: "Bericht",
//         },
//       },
//     },
//     lng: "nl", // if you're using a language detector, do not define the lng option
//     fallbackLng: "en",

//     interpolation: {
//       escapeValue: false,
//     },
//   });

// export default i18n;





import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";



import translationFR from "./langages/fr/translation.json";
import translationDE from "./langages/de/translation.json";
import translationIT from "./langages/it/translation.json";
import translationEN from "./langages/en/translation.json";
import translationJP from "./langages/jp/translation.json";



i18n
    .use(LanguageDetector) // passes LanguageDetector down to i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // react: {
        //     // Permettre le rendu HTML dans les traductions
        //     useSuspense: false,
        //   },
        resources: {
            fr: {
                translation: translationFR,
            },
            de: {
                translation: translationDE,
            },
            it: {
                translation: translationIT,
            },
            en: {
                translation: translationEN,
            },
            jp: {
                translation: translationJP,
            },
        



        detection: {
            order: ['navigator', 'htmlTag'],
            },
            fallbackLng: 'en', // Langue par défaut si la détection échoue
            interpolation: {
            escapeValue: false,
            },
        },


        // detection: {
        //     // order and from where user language should be detected
        //     order: [
        //     'navigator',
        //     'querystring',
        //     'cookie',
        //     'localStorage',
        //     'sessionStorage',
        //     'htmlTag',
        //     'path',
        //     'subdomain',
        //     ],
        
        //     // keys or params to lookup language from
        //     lookupQuerystring: 'lng',
        //     lookupCookie: 'i18next',
        //     lookupLocalStorage: 'i18nextLng',
        //     lookupFromPathIndex: 0,
        //     lookupFromSubdomainIndex: 0,
        
        //     // cache user language on
        //     caches: ['localStorage', 'cookie'],
        //     excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
        
        //     // optional expire and domain for set cookie
        //     cookieMinutes: 10,
        //     cookieDomain: 'myDomain',
        
        //     // optional htmlTag with lang attribute, the default is:
        //     htmlTag: document.documentElement,
        
        //     // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
        //     cookieOptions: { path: '/' },
        // },

        // },
        // // lng: "nl", // if you're using a language detector, do not define the lng option
        // fallbackLng: "en",

        // interpolation: {
        // escapeValue: false,
        // },


    });

export default i18n;
{
    "langue": "English",

    "install": {
        "title": "Install the TWS App!",
        "text": "TrueWindSpeed installs easily on computer or phone. The App takes up no space, as it's a Webapp!",
        "button": "Click to install the App!"
    },

    "contact": {
        "phone": "Phone",
        "mail": "Mail",
        "button": "Click to install the App!"
    },

    "nav": {
        "Direct": "Live",
        "Map": "Map",
        "Montre": "Watch"
    },

    "info": {
        "legend": {
            "header": "Legends",
            "varbl": "Variable",
            "descr": "Description",

            "hist_forecast": "Forecast history",

            "wbt": "Wet bulb temperature",
            "tg": "Black globe temperature",
            "ta": "Air temperature",
            "rh": "Relative humidity",

            "wbgt-M": "WBGT - Metabolism",

            "redlimit": "Limit for acclimatized individuals",
            "orangelimit": "Limit for non-acclimatized individuals",
            "safezone": "Safety zone",
            "coldzone": "Cold zone",
            "coldnote": "attention to the implementation of certain materials",
            "M": "Estimation of energy metabolism",
            "CAV": "Adjustment value for clothing"
        },

        "M": {
            "#0": "Rest",
            "#1": "Low",
            "#2": "Moderate",
            "#3": "High",
            "#4": "Very high"
        },

        "ME": {
            "header": "Classification of energy metabolism levels according to ISO 8996",
            "title": {
                "#0": "Rest",
                "#1": "Low energy metabolism",
                "#2": "Moderate energy metabolism",
                "#3": "High energy metabolism",
                "#4": "Very high energy metabolism"
            },
            "text": {
                "#0": "Rest, comfortable sitting position",
                "#1": "Light manual work (writing, typing, drawing, sewing, accounting); work of hands and arms (small bench tools, inspection, assembly or sorting of light materials); work of arms and legs (driving a vehicle under normal conditions, operating a foot switch or pedal).\n\nStanding: drilling (small parts); milling (small parts); winding; winding small armatures; machining with low-power tools; occasional walking on a flat surface (speed up to 2.5 km/h).",
                "#2": "Sustained work of hands and arms (nailing, filing); work of arms and legs (maneuvering on truck, tractor or equipment sites); work of arms and trunk (pneumatic hammer work, vehicle coupling, plastering, intermittent handling of moderately heavy materials, weeding, hoeing, fruit or vegetable picking; pushing or pulling light carts or wheelbarrows; walking at a speed of 2.5 to 5.5 km/h on a flat surface; forging).",
                "#3": "Intense work of arms and trunk; transport of heavy materials; shoveling; hammering work; sawing, planing or chiseling hardwood; manual mowing; excavation; walking at a speed of 5.5 to 7 km/h on a flat surface.\n\nPushing or pulling heavily loaded hand carts or wheelbarrows; removal of chips from molded parts; laying concrete blocks.",
                "#4": "Very intense activity at a fast pace close to maximum; axe work; shoveling or excavation at an intensive pace; climbing stairs, a ramp or a ladder; fast walking with short steps; running on a flat surface; walking at a speed greater than 7 km/h on a flat surface."
            },
            "richtext": {
                "#0": "Rest, comfortable sitting position",
                "#1": "Light manual work (writing, typing, drawing, sewing, accounting); work of hands and arms (small bench tools, inspection, assembly or sorting of light materials); work of arms and legs (driving a vehicle under normal conditions, operating a foot switch or pedal).<br/><br/> Standing: drilling (small parts); milling (small parts); winding;<br/> winding small armatures; machining with low-power tools; occasional walking on a flat surface (speed up to 2.5 km/h).",
                "#2": "Sustained work of hands and arms (nailing, filing); work of arms and legs (maneuvering on truck, tractor or equipment sites); work of arms and trunk (pneumatic hammer work, vehicle coupling, plastering, intermittent handling of moderately heavy materials, weeding, hoeing, fruit or vegetable picking; pushing or pulling light carts or wheelbarrows; walking at a speed of 2.5 to 5.5 km/h on a flat surface; forging).",
                "#3": "Intense work of arms and trunk; transport of heavy materials; shoveling; hammering work; sawing, planing or chiseling hardwood; manual mowing; excavation; walking at a speed of 5.5 to 7 km/h on a flat surface.<br/><br/>Pushing or pulling heavily loaded hand carts or wheelbarrows; removal of chips from molded parts; laying concrete blocks.",
                "#4": "Very intense activity at a fast pace close to maximum; axe work; shoveling or excavation at an intensive pace; climbing stairs, a ramp or a ladder; fast walking with short steps; running on a flat surface; walking at a speed greater than 7 km/h on a flat surface."
            },

            "#0": "Rest",
            "#1": "Low energy metabolism",
            "#2": "Moderate energy metabolism",
            "#3": "High energy metabolism",
            "#4": "Very high energy metabolism"
        },


        "CAV": {
            "header": "Adjustment values of the WBGT index for clothing (CAV) for different clothing outfits in °C-WBGT",
            "title": {
                "sc": "Clothing without hood",
                "sc_a": "Work clothes",
                "sc_b": "Overalls",
                "sc_c": "Non-woven SMS overalls in a single layer",
                "sc_d": "Non-woven polyolefin overalls in a single layer",
                "sc_e": "Vapor-proof apron with long sleeves and long overalls",
                "sc_f": "Double layer of woven clothing",
                "sc_g": "Vapor-proof overalls in a single layer, without hood",
                "sc_h": "Vapor-proof over overalls, without hood",
    
                "ac": "Clothing with hood",
                "ac_a": "Work clothes + hood",
                "ac_b": "Overalls + hood",
                "ac_c": "Non-woven SMS overalls in a single layer + hood",
                "ac_d": "Non-woven polyolefin overalls in a single layer + hood",
                "ac_e": "Vapor-proof apron with long sleeves and long overalls + hood",
                "ac_f": "Double layer of woven clothing + hood",
                "ac_g": "Vapor-proof overalls with hood in a single layer",

                "c": "Hood"
            },
            "text": {
                "sc_a": "Work clothes in woven fabric are the reference outfit.",
                "sc_b": "Woven fabric containing treated cotton.",
                "sc_c": "Non-proprietary process to manufacture non-woven fabrics from polypropylene.",
                "sc_d": "Proprietary fabric made from polyethylene.",
                "sc_e": "The wrap-around apron configuration is designed to protect the front and side parts of the body from accidental spills of chemicals.",
                "sc_f": "Generally considered as overalls over work clothes.",
                "sc_g": "The actual effect depends on the level of humidity and, in many cases, the effect is minor.",
                "sc_h": "-",

                "ac_g": "The actual effect depends on the level of humidity and, in many cases, the effect is minor.",

                "c": "Wearing a hooded fabric with any clothing outfit. This value is added to the CAV value of the outfit without hood or respiratory equipment.",
                
                "note": "NOTE: For clothing with high resistance to evaporation, there is a dependence on relative humidity. CAV values represent the likely high value."
            },

            "richtext": {
                "c": "Wearing a hooded fabric with any clothing outfit.<br/><br/><i>This value is added to the CAV value of the outfit without hood or respiratory equipment.</i>",
                
                "note": "<i>NOTE: For clothing with high resistance to evaporation, there is a dependence on relative humidity. CAV values represent the likely high value.</i>"
            }
        }
  },

  

  "Chart": {
    "spotselect": "Select a spot",
    "spotnotfound": "Spot not found",

    "Metabolisme": "Metabolism",

    "start": "Start",
    "end": "End",


    "Perso": "Personalized",
    "2h3h6h12h24h": "2h - 6h - 12h - 24h",
    
    "2h": "2 h",
    "6h": "6 h",
    "12h": "12 h",
    "24h": "24 h",
    "2d_prev": "2 j prév."
      
  },


    "Map": {
        "h":{
            "Options": "Options",
            "Map": "Map",
            "swisslayer": "Additional Layers"
        },
        "button":{
            "Wind": "Wind",
            "WBGT": "WBGT",
            "Temp": "Temp.",
            "Temperature": "Température",
            "Hum": "Humidity",
            "Press": "Pressure",
            "Lux": "Lux",
            "Water": "Water",
            "Wave": "Wave",
            "Period": "Period",
            "Tide": "Tide"
        }
    },

    "ExportButton": {
        "export": "Export",
        "csv": "Export CSV",
        "excel": "Export Excel",
        "pdf": "Export PDF",
        "pdf_hot": "Export PDF - Heat",
        "pdf_cold": "Export PDF - Cold"
    },


    "Certif": {

        "cert_clim": "Climate Certificate",
        "cert": "Certificate",
        "hot": "High Temperatures",
        "cold": "Extreme Cold",
        "lab": "Measurements taken with WBGT¹ temperature sensors built according to ISO 7243 standard, undergoing testing² by an ISO 1725 accredited laboratory.",

        "comp": "Company",
        "ide": "IDE",
        "lieu": "Location Name",
        "long": "Longitude",
        "lat": "Latitude",
        "date": "Certificate Issuance Date",
        "start": "Start",
        "end": "End",
        "metabo": "Energy Metabolism",
        "cav": "CAV⁴ (clothing)",

        "exmp": "Example",
        "comm": "Comment",
        "shirt": "Clothing",

        "bp1": "¹ The WBGT temperature index quantifies thermal stress on the body, related to temperature, humidity, and direct and indirect solar radiation.",
        "bp2": "² Upon request, we can provide you with our measurement protocol and quality control procedures.",

        "voir": "View",
        "Anx_a": "Annex A",
        "Anx_b": "Annex B",

        "redPeriode": "Period(s) beyond the limit for acclimatized individuals",
        "orangePeriode": "Period(s) beyond the limit for non-acclimatized individuals",
        "coldPeriode": "Period(s) of temperature below 5°C",



        "csvxls":{
            "date": "Date",
            "limAcc": "Acclimatized Limit °C",
            "limNAcc": "Non-Acclimatized Limit °C",
            "temp": "Temperature"
        }

    }
    

}

{
    "langue": "Deutsch",


    "install": {
        "title": "Installieren Sie die TWS-App!",
        "text": "TrueWindSpeed lässt sich einfach auf dem Computer oder dem Telefon installieren. Die App benötigt keinen Speicherplatz, da es sich um eine Web-App handelt!",
        "button": "Klicken Sie hier, um die App zu installieren!"
    },

    "contact": {
        "phone": "Telefon",
        "mail": "E-Mail",
        "button": "Klicken Sie hier, um die App zu installieren!"
    },

    "nav": {
        "Direct": "Direkt",
        "Map": "Karte",
        "Montre": "Uhr"
    },

    "info": {
        "legend": {
            "header": "Legenden",
            "varbl": "Variable",
            "descr": "Beschreibung",

            "hist_forecast": "Verlauf der Prognosen",

            "wbt": "Nasse-Birnen-Temperatur",
            "tg": "Schwarzkugel-Temperatur",
            "ta": "Lufttemperatur",
            "rh": "Relative Luftfeuchtigkeit",

            "wbgt-M": "WBGT - Stoffwechsel",

            "redlimit": "Grenze für akklimatisierte Personen",
            "orangelimit": "Grenze für nicht akklimatisierte Personen",
            "safezone": "Sicherheitszone",
            "coldzone": "Kältezone",
            "coldnote": "Achten Sie auf die Verwendung bestimmter Materialien",
            "M": "Schätzung des Energiestoffwechsels",
            "CAV": "Anpassungswert für Kleidung"
        },

        "M": {
            "#0": "Ruhe",
            "#1": "Niedrig",
            "#2": "Mäßig",
            "#3": "Hoch",
            "#4": "Sehr hoch"
        },        

        "ME": {
            "header": "Einstufung der Energie-Stoffwechselstufen gemäß ISO 8996",
            "title": {
                "#0": "Ruhen",
                "#1": "Niedriger Energiestoffwechsel",
                "#2": "Mäßiger Energiestoffwechsel",
                "#3": "Hoher Energiestoffwechsel",
                "#4": "Sehr hoher Energiestoffwechsel"
            },
            "text": {
                "#0": "Ruhen, bequeme Sitzposition",
                "#1": "Leichte manuelle Arbeit (Schreiben, Tippen, Zeichnen, Nähen, Buchhaltung); Arbeit mit Händen und Armen (kleine Werkzeuge auf der Werkbank, Inspektion, Montage oder Sortierung leichter Materialien); Arbeit mit Armen und Beinen (Fahren eines Fahrzeugs unter normalen Bedingungen, Betätigen eines Fußschalters oder Pedals).\n\nStehend: Bohren (kleine Teile); Fräsen (kleine Teile); Wicklung; Wickeln kleiner Anker; Bearbeitung mit Niedrigleistungswerkzeugen; gelegentliches Gehen auf einer flachen Oberfläche (Geschwindigkeit bis zu 2,5 km/h).",
                "#2": "Fortgesetzte Arbeit mit Händen und Armen (Nageln, Feilen); Arbeit mit Armen und Beinen (Manövrieren auf Lastwagen-, Traktor- oder Ausrüstungsgeländen); Arbeit mit Armen und Rumpf (Arbeit mit pneumatischem Hammer, Fahrzeugkupplung, Verputzen, intermittierendes Handhaben von mittelschweren Materialien, Jäten, Hacken, Obst- oder Gemüseernte; Schieben oder Ziehen leichter Wagen oder Schubkarren; Gehen mit einer Geschwindigkeit von 2,5 bis 5,5 km/h auf einer flachen Oberfläche; Schmieden).",
                "#3": "Intensive Arbeit mit Armen und Rumpf; Transport von schweren Materialien; Schaufeln; Hammerarbeiten; Sägen, Hobeln oder Meißeln von Hartholz; manuelles Mähen; Ausheben; Gehen mit einer Geschwindigkeit von 5,5 bis 7 km/h auf einer flachen Oberfläche.\n\nSchieben oder Ziehen von stark beladenen Handwagen oder Schubkarren; Entfernen von Spänen aus geformten Teilen; Verlegen von Betonblöcken.",
                "#4": "Sehr intensive Tätigkeit in schnellem Tempo nahe am Maximum; Axtarbeit; Schaufeln oder Ausheben in intensivem Tempo; Treppensteigen, eine Rampe oder eine Leiter hinauf; schnelles Gehen mit kurzen Schritten; Laufen auf einer flachen Oberfläche; Gehen mit einer Geschwindigkeit von mehr als 7 km/h auf einer flachen Oberfläche."
            },
            "richtext": {
                "#0": "Ruhen, bequeme Sitzposition",
                "#1": "Leichte manuelle Arbeit (Schreiben, Tippen, Zeichnen, Nähen, Buchhaltung); Arbeit mit Händen und Armen (kleine Werkzeuge auf der Werkbank, Inspektion, Montage oder Sortierung leichter Materialien); Arbeit mit Armen und Beinen (Fahren eines Fahrzeugs unter normalen Bedingungen, Betätigen eines Fußschalters oder Pedals).<br/><br/>Stehend: Bohren (kleine Teile); Fräsen (kleine Teile); Wicklung;<br/> Wickeln kleiner Anker; Bearbeitung mit Niedrigleistungswerkzeugen; gelegentliches Gehen auf einer flachen Oberfläche (Geschwindigkeit bis zu 2,5 km/h).",
                "#2": "Fortgesetzte Arbeit mit Händen und Armen (Nageln, Feilen); Arbeit mit Armen und Beinen (Manövrieren auf Lastwagen-, Traktor- oder Ausrüstungsgeländen); Arbeit mit Armen und Rumpf (Arbeit mit pneumatischem Hammer, Fahrzeugkupplung, Verputzen, intermittierendes Handhaben von mittelschweren Materialien, Jäten, Hacken, Obst- oder Gemüseernte; Schieben oder Ziehen leichter Wagen oder Schubkarren; Gehen mit einer Geschwindigkeit von 2,5 bis 5,5 km/h auf einer flachen Oberfläche; Schmieden).",
                "#3": "Intensive Arbeit mit Armen und Rumpf; Transport von schweren Materialien; Schaufeln; Hammerarbeiten; Sägen, Hobeln oder Meißeln von Hartholz; manuelles Mähen; Ausheben; Gehen mit einer Geschwindigkeit von 5,5 bis 7 km/h auf einer flachen Oberfläche.<br/><br/>Schieben oder Ziehen von stark beladenen Handwagen oder Schubkarren; Entfernen von Spänen aus geformten Teilen; Verlegen von Betonblöcken.",
                "#4": "Sehr intensive Tätigkeit in schnellem Tempo nahe am Maximum; Axtarbeit; Schaufeln oder Ausheben in intensivem Tempo; Treppensteigen, eine Rampe oder eine Leiter hinauf; schnelles Gehen mit kurzen Schritten; Laufen auf einer flachen Oberfläche; Gehen mit einer Geschwindigkeit von mehr als 7 km/h auf einer flachen Oberfläche."
            },

            "#0": "Ruhen",
            "#1": "Niedriger Energiestoffwechsel",
            "#2": "Mäßiger Energiestoffwechsel",
            "#3": "Hoher Energiestoffwechsel",
            "#4": "Sehr hoher Energiestoffwechsel"
        },


        "CAV": {
            "header": "Anpassungswerte des WBGT-Index für Kleidung (CAV) für verschiedene Kleidungskombinationen in °C-WBGT",
            "title": {
                "sc": "Kleidung ohne Kapuze",
                "sc_a": "Arbeitskleidung",
                "sc_b": "Overall",
                "sc_c": "Einlagige SMS-Overalls aus Vliesstoff",
                "sc_d": "Einlagige Polyolefin-Overalls aus Vliesstoff",
                "sc_e": "Dampfdichter Schürzen mit langen Ärmeln und langen Overalls",
                "sc_f": "Doppelschicht Kleidung aus gewebtem Stoff",
                "sc_g": "Dampfdichte Einlagige Overalls ohne Kapuze",
                "sc_h": "Dampfdichte Über-Overalls ohne Kapuze",
    
                "ac": "Kleidung mit Kapuze",
                "ac_a": "Arbeitskleidung + Kapuze",
                "ac_b": "Overall + Kapuze",
                "ac_c": "Einlagige SMS-Overalls aus Vliesstoff + Kapuze",
                "ac_d": "Einlagige Polyolefin-Overalls aus Vliesstoff + Kapuze",
                "ac_e": "Dampfdichter Schürzen mit langen Ärmeln und langen Overalls + Kapuze",
                "ac_f": "Doppelschicht Kleidung aus gewebtem Stoff + Kapuze",
                "ac_g": "Dampfdichte Einlagige Overalls mit Kapuze",

                "c": "Kapuze"
            },
            "text": {
                "sc_a": "Arbeitskleidung aus gewebtem Stoff ist das Referenzoutfit.",
                "sc_b": "Gewebter Stoff mit behandeltem Baumwollanteil.",
                "sc_c": "Nicht proprietäres Verfahren zur Herstellung von Vliesstoffen aus Polypropylen.",
                "sc_d": "Proprietärer Stoff aus Polyethylen hergestellt.",
                "sc_e": "Die Wickelschürzen-Konfiguration ist so konzipiert, dass sie die vorderen und seitlichen Körperbereiche vor versehentlichen Chemikalienverschüttungen schützt.",
                "sc_f": "In der Regel als Overalls über Arbeitskleidung betrachtet.",
                "sc_g": "Der tatsächliche Effekt hängt vom Feuchtigkeitsniveau ab und ist in vielen Fällen geringfügig.",
                "sc_h": "-",

                "ac_g": "Der tatsächliche Effekt hängt vom Feuchtigkeitsniveau ab und ist in vielen Fällen geringfügig.",

                "c": "Tragen einer Kapuzenkleidung mit jeder Kleidungskombination. Dieser Wert wird zum CAV-Wert der Kleidung ohne Kapuze oder Atemschutzgerät hinzugefügt.",
                
                "note": "HINWEIS: Bei Kleidung mit hoher Verdunstungswiderstand gibt es eine Abhängigkeit von der relativen Luftfeuchtigkeit. CAV-Werte repräsentieren den wahrscheinlichen Höchstwert."
            },

            "richtext": {
                "c": "Tragen einer Kapuzenkleidung mit jeder Kleidungskombination.<br/><br/><i>Dieser Wert wird zum CAV-Wert der Kleidung ohne Kapuze oder Atemschutzgerät hinzugefügt.</i>",
                
                "note": "<i>HINWEIS: Bei Kleidung mit hoher Verdunstungswiderstand gibt es eine Abhängigkeit von der relativen Luftfeuchtigkeit. CAV-Werte repräsentieren den wahrscheinlichen Höchstwert.</i>"
            }
        }
    },

  

  "Chart": {
    "spotselect": "Wählen Sie einen Spot",
    "spotnotfound": "Spot nicht gefunden",

    "Metabolisme": "Stoffwechsel",

    "start": "Start",
    "end": "Ende",


    "Perso": "Persönlich",
    "2h3h6h12h24h": "2Std. - 6Std. - 12Std. - 24Std.",
    
    "2h": "2 Std.",
    "6h": "6 Std.",
    "12h": "12 Std.",
    "24h": "24 Std.",
    "2d_prev": "Vorherige 2 Tage"
        
  },


    "Map": {
        "h":{
            "Options": "Optionen",
            "Map": "Karte",
            "swisslayer": "Zusätzliche Schichten"
        },
        "button":{
            "Wind": "Wind",
            "WBGT": "WBGT",
            "Temp": "Temp.",
            "Temperature": "Temperatur",
            "Hum": "Luftfeuchtigkeit",
            "Press": "Druck",
            "Lux": "Lux",
            "Water": "Wasser",
            "Wave": "Welle",
            "Period": "Periode",
            "Tide": "Gezeiten"
        }
    },

    "ExportButton": {
        "export": "Exportieren",
        "csv": "CSV exportieren",
        "excel": "Excel exportieren",
        "pdf": "PDF exportieren",
        "pdf_hot": "PDF exportieren - Hitze",
        "pdf_cold": "PDF exportieren - Kälte"
    },


    "Certif": {

        "cert_clim": "Klimazertifikat",
        "cert": "Zertifikat",
        "hot": "Hohe Temperaturen",
        "cold": "Extremer Kälte",
        "lab": "Messungen mit WBGT¹-Temperatursensoren gemäß ISO 7243-Standard, die von einem nach ISO 1725 akkreditierten Labor getestet² werden.",

        "comp": "Unternehmen",
        "ide": "IDE",
        "lieu": "Ortsname",
        "long": "Längengrad",
        "lat": "Breitengrad",
        "date": "Ausstellungsdatum des Zertifikats",
        "start": "Start",
        "end": "Ende",
        "metabo": "Energiestoffwechsel",
        "cav": "CAV⁴ (Kleidung)",

        "exmp": "Beispiel",
        "comm": "Kommentar",
        "shirt": "Kleidung",

        "bp1": "¹ Der WBGT-Temperaturindex quantifiziert den thermischen Stress auf den Körper, der mit Temperatur, Luftfeuchtigkeit und direkter und indirekter Sonneneinstrahlung verbunden ist.",
        "bp2": "² Auf Anfrage können wir Ihnen unser Messprotokoll und unsere Qualitätskontrollverfahren vorlegen.",

        "voir": "Anzeigen",
        "Anx_a": "Anhang A",
        "Anx_b": "Anhang B",

        "redPeriode": "Zeitraum(e) über der Grenze für akklimatisierte Personen",
        "orangePeriode": "Zeitraum(e) über der Grenze für nicht akklimatisierte Personen",
        "coldPeriode": "Zeitraum(e) mit einer Temperatur unter 5°C",



        "csvxls":{
            "date": "Datum",
            "limAcc": "Akklimatisierte Grenze °C",
            "limNAcc": "Nicht-akklomatisierte Grenze °C",
            "temp": "Temperatur"
        }
    }
}
